import React from "react";

const defaultState = {
  color: "peach",
  setColor: () => {},
};

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
  state = {
    color: "peach",
  };

  setColor = (color) => {
    window.localStorage.setItem("color", color);
    this.setState({ color: color });
    document.querySelector("body").className = color;
  };

  componentDidMount() {
    const color = window.localStorage.getItem("color");

    if (color !== null) {
      this.setState({ color: color });
      document.querySelector("body").className = color;
    }
  }

  render() {
    const { children } = this.props;
    const { color } = this.state;
    return (
      <ThemeContext.Provider
        value={{
          color,
          setColor: this.setColor,
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;

export { ThemeProvider };
